
import { useState } from 'react';
import {Container, Card} from 'react-bootstrap';

import DummyTableList from '../data/tables.json'


export default function DataCatalogTableList({setUserChoiceParent}) {
    const [userChoice,setUserChoice] = useState("");

  return (
    <>
    {/* <div>DataCatalogTableList</div> */}
    <Card className="content-scroll-card">
        <Card.Header>
            <Card.Title as="h5">Data Catalog</Card.Title>
        </Card.Header>
        <Card.Body>
            
            {/* userChoice={userChoice} */}

            <Card.Text className="content-scroll-card">
                <table className="table table-responsive table-bordered table-hover" >
                <thead className="table-dark">
                    <tr>
                    <th>Source Application</th>
                    <th>Table Name</th>
                    </tr>
                </thead>
                <tbody>
                    {DummyTableList && DummyTableList.map(item => (
                        <tr 
                            key={item.tablename} 
                            onClick={e => {setUserChoice(item.systemname+'.'+item.tablename);setUserChoiceParent(item.systemname+'.'+item.tablename)}}
                            className={userChoice===item.systemname+'.'+item.tablename?'table-primary':null}
                            >
                        <td>{item.systemname}</td>
                        <td>{item.tablename}</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </Card.Text>
        </Card.Body>
    </Card>

    </>

    
  )
}
