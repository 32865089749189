import { Container, Row, Col, Carousel, Image } from 'react-bootstrap';
import './MyCarousel.css'

function MyCarousel({ items, size }) {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={size}>
          <Carousel className="mycarousel">
            {items.map((item, index) => (
              <Carousel.Item key={index}>
                <Image src={item.image} rounded />
                <Carousel.Caption>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default MyCarousel;

