import { Accordion, Container, Row, Col, Button, Table, Card, Badge } from 'react-bootstrap';
import MyCarousel from '../components/MyCarousel';

import carouselImage1 from '../images/home-carousel-image-1.jpg';
import carouselImage2 from '../images/home-carousel-image-2.jpg';
import carouselImage3 from '../images/home-carousel-image-3.jpg';
import carouselImage4 from '../images/home-carousel-image-4.jpg';
import carouselImage5 from '../images/home-carousel-image-5.jpg';

const carouselItems = [
  {
    image: carouselImage1,
    title: "Data On Demand",
    description: "Generate large datasets on demand. Describe your dataset in a few clicks and generate synthetic datasets for kick-starting your data analytics or data science projects."
  },
  {
    image: carouselImage2,
    title: "Rich Metadata on your Fingertips",
    description: "Automatically generate rich metadata and descriptions of your datasets and fields using the power of AI."
  },
  {
    image: carouselImage3,
    title: "Rapid Multi-Dimensional Insights",
    description: "Benefit from rapid insights into your business data - without the overhead of long development and deployments."
  },
  {
    image: carouselImage4,
    title: "A Trusted and Capable Analyst",
    description: "An assistant you can rely on to understand your data, ask questions about it, and unlock its value."
  },
  {
    image: carouselImage5,
    title: "Omnipresent Power of Data and Insights",
    description: "Quick access to your key data - across all platforms and devices."
  }
];


export default function SyntheticDataGeneration() {
  return (
    <>
    <Container className="content">
    <h1>Synthetic Data Generation
      &nbsp;<Badge bg="info">BETA</Badge>
    </h1>
    <Row>
      <Col>
      
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>💡 Ready to Get Started?</Accordion.Header>
          <Accordion.Body>
            Reach out to <a href="#">KPMG Lighthouse</a> for more details.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      </Col>
    </Row>
    <Row>
      <Col>
      
        <MyCarousel items={carouselItems} size={12} />

      </Col>
    </Row>


    <Row className="mt-4">
      {[...Array(3)].map((_, idx) => (
        <Col key={idx} md={4}>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Feature {idx + 1}</Card.Title>
              <Card.Text>
                Some quick example text to highlight the feature.
              </Card.Text>
              <Button variant="primary">Learn More</Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>

    <Row className="mt-4">
      {[...Array(3)].map((_, idx) => (
        <Col key={idx + 3} md={4}>
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Feature {idx + 4}</Card.Title>
              <Card.Text>
                Some quick example text to highlight the feature.
              </Card.Text>
              <Button variant="primary">Learn More</Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>



    </Container>
    </>
  )
}
