
import { useState } from 'react';
import { Accordion, Container, Row, Col, Button, Table, Badge } from 'react-bootstrap';
import DataCatalogTableList from '../components/DataCatalogTableList';
import DataCatalogTableMetadata from '../components/DataCatalogTableMetadata';

export default function MedataEnrichment() {
  const [userChoice,setUserChoice] = useState("");

  return (
    <>
    <Container className="content">
    <h1>Metadata Enrichment&nbsp;<Badge bg="primary">NEW</Badge></h1>
    <Row>
      <Col>
      
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>💡 New to Metadata Enrichment?</Accordion.Header>
          <Accordion.Body>
            Here's how Metadata Enrichment works:  Seamlessly integrating AI tools, our platform enhances metadata of your datasets with assistance from Generative AI, but with a human-in-the-loop review. Users can easily populate the catalog with business glossaries and table/column descriptions via a user-friendly interface. This make the data more accessible by all teams fostering collaboration and faster innovation cycles. 
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      </Col>
    </Row>
    <Row>
      <Col md={4}>
        
        {/* <div>userChoice ={userChoice} </div> */}

        <DataCatalogTableList setUserChoiceParent={setUserChoice} />
      </Col>
      <Col md={8}>
        <DataCatalogTableMetadata userChoice={userChoice} />
      </Col>
    </Row>
    {/* <Row>
      <Col className="text-end">
        <Button variant="primary">🖉 Fetch Suggestions</Button>{' '}
        <Button variant="secondary">Save</Button>{' '}
      </Col>
    </Row> */}

    
    </Container>
    </>


  )
}
