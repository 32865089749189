import 'bootswatch/dist/quartz/bootstrap.min.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import {Container, Row, Col, Button, Alert} from 'react-bootstrap';
import MyNavbar from './components/MyNavbar';

import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';

import Home from './pages/Home';
import About from './pages/About';
import Showcase from './pages/Showcase';
import Solutions from './pages/Solutions';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import MedataEnrichment from './pages/MedataEnrichment';
import SyntheticDataGeneration from './pages/SyntheticDataGeneration';
import DataAnalysis from './pages/DataAnalysis';
import DataVisualization from './pages/DataVisualization';
import IndustrySolutions from './pages/IndustrySolutions';
import Login from './pages/Login';


function App() {
  const [authStatus, setAuthStatus] = useState(false);

  const handleLogout = () => {
    setAuthStatus(false);
  }

  return (
    <>

    {authStatus? <MyNavbar onLogout={handleLogout} /> : null}

    <Routes>
      <Route path="/" element={authStatus? <Home /> : <Login setAuthStatusParent={setAuthStatus} />} />
      <Route path="/about" element={<About />} />
      <Route path="/showcase" element={<Showcase />} />
      <Route path="/solutions" >
        <Route index element={<Solutions />} />
        <Route path="metadata-enrichment" element={<MedataEnrichment />} />
        <Route path="data-generation" element={<SyntheticDataGeneration  />} />
        <Route path="data-analysis" element={<DataAnalysis />} />
        <Route path="data-visualization" element={<DataVisualization />} />
        <Route path="industry-solutions" element={<IndustrySolutions />} />
      </Route>
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>

    </>
  );
}

export default App;
