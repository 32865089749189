
import { useState } from 'react';
import { Container, Row, Col, Button, Card, Accordion } from 'react-bootstrap'

import DummyTableMetaData from '../data/table-metadata.json'



export default function DataCatalogTableMetadata({userChoice}) {
    const [fetchSuggestions,setFetchSuggestions] = useState(false);
    const [saveButton,setSaveButton] = useState(false);

    // function evalMetdataDisplay (bool, value) {
    //     if(bool && !{fetchSuggestions})
    //     {
    //         if ({saveButton})
    //         {
    //             return ("<input value="+value+"type='text' className='metadata-userinput' />");
    //         }
    //         else
    //         {
    //             return ("<input value="+value+"type='text' className='metadata-userinput-saved' />");
    //         }
    //     }
    //     else return ""
    // }

  return (
    <>
    {/* <div>DataCatalogTableMetadata</div> */}

    <Card className="content-scroll-card">
        <Card.Header>
            <Card.Title as="h5">Table Metadata</Card.Title>
        </Card.Header>
        <Card.Body>
            <Card.Text className="content-scroll-card">

            {/* userChoice={userChoice}<br/>
            fetchSuggestions={String(fetchSuggestions)}<br/>
            saveButton={String(saveButton)}<br/> */}

            {!userChoice ? "Select a Table from the Data Catalog to view Table Metadata":
                <Accordion defaultActiveKey={['0','1']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Table ▦ &nbsp; <strong> {DummyTableMetaData.database_name} . {DummyTableMetaData.table_name}</strong></Accordion.Header>
                        <Accordion.Body className="text-secondary-emphasis">

                            <div>
                                {Object.keys(DummyTableMetaData.table_header).map((key,i) => (
                                    <div key={i}>
                                        <strong>{key}</strong> : &nbsp;

                                        {key.startsWith("business") && !saveButton
                                        ?<input value={fetchSuggestions ? DummyTableMetaData.table_header[key] : null} type="text" className={fetchSuggestions? "metadata-userinput-fetched" : "metadata-userinput" } />
                                        :DummyTableMetaData.table_header[key]}

                                        {/* {key.startsWith("business")
                                        ?<input value={DummyTableMetaData.table_header[key]} type="text" className="metadata-userinput" />
                                        :DummyTableMetaData.table_header[key]} */}

                                        {/* {evalMetdataDisplay(key.startsWith("business"),DummyTableMetaData.table_header[key])} */}
                                        
                                    </div>
                                ))}
                            
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Columns ▥ </Accordion.Header>
                        <Accordion.Body>
                        <table className="table table-responsive table-bordered table-hover" >
                                <thead className="table-dark">
                                    <tr>
                                    <th>Column Name </th>
                                    <th>Datatype</th>
                                    <th>Primary Key</th>
                                    <th>Business Name</th>
                                    <th>Business Description</th>
                                    {/* <th>Comments</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {DummyTableMetaData.table_columns && DummyTableMetaData.table_columns.map(item => (
                                        <tr key={item.column_name}>
                                        <td>{item.column_name}</td>
                                        <td>{item.column_datatype}</td>
                                        <td>{String(item.primary_key)}</td>
                                        <td>
                                            {saveButton ? item.business_name
                                            : <input value={fetchSuggestions ? item.business_name : null} type="text" className={fetchSuggestions ? "metadata-userinput-fetched" : "metadata-userinput"} />
                                            }
                                            {/* <input value={fetchSuggestions ? item.business_name : null} type="text" className={saveButton? "metadata-userinput-saved" : "metadata-userinput" } /> */}
                                        </td>
                                        <td>
                                            {saveButton ? item.business_description
                                            : <input value={fetchSuggestions ? item.business_description : null} type="text" className={fetchSuggestions ? "metadata-userinput-fetched" : "metadata-userinput"} />
                                            }
                                            {/* <input value={fetchSuggestions ? item.business_description : null} type="text" className={saveButton? "metadata-userinput-saved" : "metadata-userinput" } /> */}
                                        </td>
                                        {/* <td>{item.comments}</td> */}
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                        </Accordion.Body>
                    </Accordion.Item>   
                </Accordion>
                }
            </Card.Text>
        </Card.Body>
    </Card>

    <Container>
        <Row>
            <Col className="text-end">
                <Button variant="primary" onClick={e => setFetchSuggestions(true)}>🖉 Fetch Suggestions</Button>{' '}
                <Button variant="primary" onClick={e => setSaveButton(true)}>Save</Button>{' '}
                <Button variant="secondary" onClick={e => setSaveButton(false)}>Edit</Button>{' '}
                <a href="../data/table-metadata.json">
                    <Button variant="secondary">🡇 Export</Button>{' '}
                </a>
            </Col>
        </Row>
    </Container>
    
    </>
  )
}
