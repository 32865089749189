import React from 'react'
import {Button, Form, Container, Row, Col} from 'react-bootstrap'

import logo from '../assets/genie-logotype.svg';

export default function Login({setAuthStatusParent}) {


  return (
    <>
    
    {/* Login Form */}

    <Container className="genie-login align-items-center 100-w vh-100 p-5" fluid>
        <Row >
            <Col className="text-center col-md-4 offset-md-4">
            <img
                alt="Genie"
                src={logo}
                height="60"
                className="d-inline-block align-top"
            />
            {/* <h1>KPMG Genie</h1> */}
            <hr/>
            <p className="lead">Login with your credentials to get started!</p>
            </Col>
        </Row>
        <Row>
            <Col className="col-md-4 offset-md-4">
            <br />
                 <Form className='login-form'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                        <Form.Text className="text-muted">
                            Remember to use the same email address you used to register. <a href="#">Forgot UserID?</a>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                        <Form.Text className="text-muted">
                            Passwords are case sensitive and must comply with the minimum complexity requirements. <a href="#">Forgot Password?</a>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me on this device" />
                    </Form.Group>
                </Form>
            
            </Col>
        </Row>
        <Row>
            <Col className="text-center col-md-4 offset-md-4">
                    <Button variant="primary" onClick={e => {if ( document.querySelector('#formBasicPassword').value === 'HukumMereData') {setAuthStatusParent(true)}}}
                    >
                        Submit
                    </Button>
                    &nbsp;&nbsp;&nbsp; 
                    <Button variant="secondary" disabled>Create an Account*</Button>
                    <p><small className="text-body-tertiary">*Currently available in a limited preview.</small></p>
            </Col>  
        </Row>
    </Container>




    </>
  )
}
