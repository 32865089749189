import logo from '../assets/genie-logotype.svg';
import {Container, Row, Col, Navbar, Nav, NavDropdown, Button, Badge} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';

function MyNavbar({ onLogout }) {
  const navigate = useNavigate();

  const handleUserLogout = () => {
    // Navigate the user to the Login page
    navigate('/');
    // If there's any other logic you'd like to execute during logout, you can add it here.
    onLogout();
  };

  return (
    <>

        <Navbar className="bg-primary" data-bs-theme="dark" expand="lg" fixedTop>
        <Container fluid>
          <LinkContainer to="/">
            <Navbar.Brand>
            <img
                alt="Genie"
                src={logo}
                height="30"
                className="d-inline-block align-top"
            />{' '}
            </Navbar.Brand>
          </LinkContainer>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse className="collapse navbar-collapse" id="navbarResponsive">
            <Nav className="navbar-nav me-auto">

                <LinkContainer to="/about">
                  <Nav.Link active={false}>About</Nav.Link> 
                </LinkContainer>
                <LinkContainer to="/showcase">
                  <Nav.Link active={false}>Showcase</Nav.Link> 
                </LinkContainer>

                <NavDropdown title="Solutions" id="basic-nav-dropdown">
                  <LinkContainer to="/solutions/metadata-enrichment">
                    <NavDropdown.Item >Metadata Enrichment
                      &nbsp;<Badge bg="primary">NEW</Badge>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/solutions/data-generation">
                    <NavDropdown.Item >Synthetic Data Generation
                      &nbsp;<Badge bg="info">BETA</Badge>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/solutions/data-visualization">
                    <NavDropdown.Item >Data Visualization
                    &nbsp;<Badge bg="info">BETA</Badge>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/solutions/data-analysis">
                    <NavDropdown.Item >Data Analysis
                    &nbsp;<Badge bg="info">BETA</Badge>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/solutions/industry-solutions">
                    <NavDropdown.Item >Metadata Enrichment
                    &nbsp;<Badge bg="warning">v2</Badge>
                    &nbsp;<Badge bg="secondary">COMING SOON</Badge>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer to="/solutions/industry-solutions2">
                    <NavDropdown.Item >Industry Solutions
                    &nbsp;<Badge bg="secondary">COMING SOON</Badge>
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>

                <LinkContainer to="/tools">
                  <Nav.Link active={false}>Tools</Nav.Link> 
                </LinkContainer>
                <LinkContainer to="/settings">
                  <Nav.Link active={false}>Settings</Nav.Link> 
                </LinkContainer>
                <LinkContainer to="/contact">
                  <Nav.Link active={false}>Contact</Nav.Link> 
                </LinkContainer>

            </Nav>
            

            <Nav>
                    <NavDropdown title={<i className="bi bi-person-circle"></i>} id="profile-dropdown" align="end" >
                        <NavDropdown.Item >User Profile</NavDropdown.Item>
                        <NavDropdown.Item >Settings</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleUserLogout} >Logout</NavDropdown.Item>
                    </NavDropdown>
                    
                    <NavDropdown title={<i className="bi bi-palette"></i>} id="theme-dropdown" align="end" >
                        <NavDropdown.Item >Dark</NavDropdown.Item>
                        <NavDropdown.Item >Light</NavDropdown.Item>
                    </NavDropdown>
                </Nav>

            </Navbar.Collapse>

        </Container>
        </Navbar>
        
    </>
  );
}

export default MyNavbar;
