import { Accordion, Container, Row, Col, Button, Table, Badge } from 'react-bootstrap';

export default function DataVisualization() {
  return (
    <>
    <Container className="content">
    <h1>Data Visualization
      &nbsp;<Badge bg="info">BETA</Badge>
    </h1>
    <Row>
      <Col>
      
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>💡 Ready to Get Started?</Accordion.Header>
          <Accordion.Body>
            Reach out to <a href="#">KPMG Lighthouse</a> for more details.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      </Col>
    </Row>
    </Container>
    </>
  )
}
