
import {Container, Row, Col, Button, Alert} from 'react-bootstrap';
import MyCarousel from '../components/MyCarousel';
import MyBannerMessage from '../components/MyBannerMessage';

import carouselImage1 from '../images/home-carousel-image-1.jpg';
import carouselImage2 from '../images/home-carousel-image-2.jpg';
import carouselImage3 from '../images/home-carousel-image-3.jpg';
import carouselImage4 from '../images/home-carousel-image-4.jpg';
import carouselImage5 from '../images/home-carousel-image-5.jpg';

const carouselItems = [
  {
    image: carouselImage1,
    title: "Data On Demand",
    description: "Generate large datasets on demand. Describe your dataset in a few clicks and generate synthetic datasets for kick-starting your data analytics or data science projects."
  },
  {
    image: carouselImage2,
    title: "Rich Metadata on your Fingertips",
    description: "Automatically generate rich metadata and descriptions of your datasets and fields using the power of AI."
  },
  {
    image: carouselImage3,
    title: "Rapid Multi-Dimensional Insights",
    description: "Benefit from rapid insights into your business data - without the overhead of long development and deployments."
  },
  {
    image: carouselImage4,
    title: "A Trusted and Capable Analyst",
    description: "An assistant you can rely on to understand your data, ask questions about it, and unlock its value."
  },
  {
    image: carouselImage5,
    title: "Omnipresent Power of Data and Insights",
    description: "Quick access to your key data - across all platforms and devices."
  }
];


export default function Home() {
  return (
    <>
    <Container className="content">
      <Row>
        <Col>
          <h1>Meet Genie!</h1>
          <p className="lead">Harness the power of Generative AI to super-charge your data analytics workflows.</p>
          {/* <MyBannerMessage /> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="success" dismissible>
            <Alert.Heading>What's New?</Alert.Heading>
            <p>
              <b>KPMG Genie</b> is a data analytics platform powered by Generative AI to provide productivity power-ups at various points in your data workflows. We are adding new features and expanding existing capabilities. <a href="#" className="alert-link">Click here</a> to explore what's new.
            </p>
            <hr />
            <p className="mb-0">
              <Button variant="primary">Learn more</Button>
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
            {/* <div>Home</div> */}
            <MyCarousel items={carouselItems} size={12} />
        </Col>
      </Row>
    </Container>
    </>
  )
}
