

function MyBannerMessage () {

    fetch("https://aern9f3a0j.execute-api.us-east-1.amazonaws.com/helloworld").then(res => res.json()).then(data => console.log(data));
    // fetch("https://catfact.ninja/fact").then(res => res.json()).then(data => console.log(data));
    // console.log("done!");

  return (
    <>
    <div>MyBannerMessage</div>
    <div></div>
    </>
  )
}

export default MyBannerMessage;