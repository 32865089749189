import { Accordion, Container, Row, Col, Button, Table, Badge, Card } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function IndustrySolutions() {

  // const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  // const [dbName, setDbName] = useState("");
  const [dbTables, setDbTables] = useState([]);
  const [metadata, setMetadata] = useState(null);

  const [isLoadingTables, setIsLoadingTables] = useState(true);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);

  const [editing, setEditing] = useState({ rowIndex: null, field: null, scope: null }); // 'scope' can be 'table' or 'column'



  const API_BASE_URL = 'https://qih3mej8ug.execute-api.us-east-1.amazonaws.com/PROD';
  const GET_TABLES_URL = `${API_BASE_URL}/gettables`;
  const GET_METADATA_URL = `${API_BASE_URL}/gettablemetadata`;
  const GET_RECOMMENDATION_URL = `${API_BASE_URL}/getrecommendation`;
  // const PUT_METADATA_URL = `${API_BASE_URL}/puttablemetadata`;
  // Temporarily routing PUT requests via HTTP API instead of RESt API
  const PUT_METADATA_URL = `https://aern9f3a0j.execute-api.us-east-1.amazonaws.com/puttablemetadata`;



  useEffect(() => {
    axios.get(GET_TABLES_URL)
      .then(response => {
        if (response.data.DBS) {
          setDbTables(response.data.DBS);
        }
        setIsLoadingTables(false);
      });
  }, []);

  const handleRowClick = (dbName, table) => {
    setSelectedTable(table);
    const postData = {
      Table_Name: table,
      DB_Name: dbName
    };
    axios.post(GET_METADATA_URL, postData)
      .then(response => {
        setMetadata(response.data);
        setIsLoadingMetadata(false);
      });
  };

  const fetchRecommendations = () => {
    const postData = {
      Table_Name: selectedTable, 
      DB_Name: metadata.DB_Name,
      Recommendation_Flag: false
    };
  
    axios.post(GET_RECOMMENDATION_URL, postData)
      .then(response => {
        // This IF condition is a temporary fix -- to accunt for "Cols" versus "Columns" difference in API responses of /getrecommendations and /gettablemetadata
        if (response.data.Cols) {
          response.data.Columns = response.data.Cols;
          delete response.data.Cols;
        }
        setMetadata(response.data);
      })
      .catch(error => {
        console.error("Error fetching recommendations:", error);
      });
  };

  const handleTableInputChange = (e, field) => {
    setMetadata(prevMetadata => ({ ...prevMetadata, [field]: e.target.value }));
  };
  
  const handleColumnInputChange = (event, rowIndex, field) => {
    const newValue = event.target.value;
    setMetadata(prevMetadata => {
        const newMetadata = { ...prevMetadata };
        if (newMetadata.Cols && newMetadata.Cols[rowIndex]) {
            newMetadata.Cols[rowIndex][field] = newValue;
        }
        return newMetadata;
    });
  }


  const saveMetadata = () => {
    
    axios.put(PUT_METADATA_URL, metadata)
      .then(response => {
        console.log("Successfully saved metadata:", response.data);
        // You can implement further logic here, e.g. showing a success message to the user.
      })
      .catch(error => {
        console.error("Error saving metadata:", error);
        // Handle the error, e.g. show an error message to the user.
      });
  };
  


  return (
    <>
    <Container className="content">
    <h1>Metadata Enrichment
      &nbsp;<Badge bg="warning">v 2.0</Badge>
      &nbsp;<Badge bg="secondary">COMING SOON</Badge>
    </h1>
    <Row>
      <Col>
      
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>💡 Ready to Get Started?</Accordion.Header>
          <Accordion.Body>
            Reach out to <a href="#">KPMG Lighthouse</a> for more details.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      </Col>
    </Row>


    <Row>

      {/* Display list of tables from Data Catalog */}
      <Col md={4}>
      <h4><Badge bg="primary">Data Catalog</Badge></h4>
      <div className="table-scroll">

      {isLoadingTables 
        ? <>
        <div className="placeholder">
          <p className="spinner-border spinner-border-sm text-dark" role="status" aria-hidden="true"></p>
          &nbsp;
          <p className="text-dark">Loading Data Catalog ...</p>
        </div>
        </>
        : (
            <Table striped bordered hover>
            <thead className="table-dark">
              <tr>
                <th>Database Name</th>
                <th>Table Name</th>
              </tr>
            </thead>
              <tbody>
                {dbTables.map((db, dbIndex) => 
                  db.table_names.map((table, tableIndex) => (
                    <tr key={`${dbIndex}-${tableIndex}`} onClick={() => handleRowClick(db.database_name, table)}>
                      <td>{db.database_name}</td>
                      <td>{table}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>

          )}


      </div>
      </Col>

      {/* Display table metadata for the selected table */}
      <Col md={8}>
      <h4><Badge bg="primary">Table Metadata</Badge></h4>
      {isLoadingMetadata 
        ? <>
        <div className="placeholder">
          <p className="spinner-border spinner-border-sm text-dark" role="status" aria-hidden="true"></p>
          &nbsp;
          <p className="text-dark">Loading Table Metadata ...</p>
        </div>
        </>
        : (metadata 
          ? (
          <div>
            <Card className="mb-3">
            <Card.Body>
              <Card.Title>{metadata.Table_Name}</Card.Title>
              <Card.Text>
                <strong>Database:</strong> {metadata.DB_Name} <br />
                <strong>Type:</strong> {metadata.Table_Type} <br />
                
                {/* <strong>Business Name:</strong> {metadata.Business_Name} <br /> */}

                <strong>Business Name:</strong>&nbsp;
                {editing.scope === 'table' && editing.field === 'Business_Name' ?
                    <input
                        value={metadata.Business_Name || ""}
                        onChange={e => handleTableInputChange(e, 'Business_Name')}
                        onBlur={() => setEditing({ rowIndex: null, field: null, scope: null })}
                    /> :
                    <span 
                        className="editable-field" 
                        onDoubleClick={() => setEditing({ rowIndex: null, field: 'Business_Name', scope: 'table' })}>
                        {metadata.Business_Name || "Click to edit"}
                    </span>
                }<br />
                

                {/* <strong>Description:</strong> {metadata.Business_Description} <br /> */}

                <strong>Business Description:</strong>&nbsp;
                {editing.scope === 'table' && editing.field === 'Business_Description' ?
                    <input
                        value={metadata.Business_Description || ""}
                        onChange={e => handleTableInputChange(e, 'Business_Description')}
                        onBlur={() => setEditing({ rowIndex: null, field: null, scope: null })}
                    /> :
                    <span 
                        className="editable-field" 
                        onDoubleClick={() => setEditing({ rowIndex: null, field: 'Business_Description', scope: 'table' })}>
                        {metadata.Business_Description || "Click to edit"}
                    </span>
                } <br />
                

              </Card.Text>
            </Card.Body>
            </Card>

            <div className="table-scroll">
              <Table striped bordered hover>
                <thead className="table-dark">
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Business Name</th>
                    <th>Business Description</th>
                  </tr>
                </thead>
                <tbody>
                  {metadata.Columns.map((column, index) => (
                    <tr key={index}>
                      <td>{column.Name}</td>
                      <td>{column.Type}</td>

                      {/* <td>{column.Business_Name}</td>
                      <td>{column.Business_Description}</td> */}

                      <td>
                        {editing.scope === 'column' && editing.field === 'Business_Name' && editing.rowIndex === index ?
                          <input
                              value={column.Business_Name || ""}
                              onChange={e => handleColumnInputChange(e, index, 'Business_Name')}
                              onBlur={() => setEditing({ rowIndex: null, field: null, scope: null })}
                          /> :
                          <span 
                              className="editable-field" 
                              onDoubleClick={() => setEditing({ rowIndex: index, field: 'Business_Name', scope: 'column' })}>
                              {column.Business_Name || "Click to edit"}
                          </span>
                        }
                      </td>
                      <td>
                       {editing.scope === 'column' && editing.field === 'Business_Description' && editing.rowIndex === index ?
                            <input
                                value={column.Business_Description || ""}
                                onChange={e => handleColumnInputChange(e, index, 'Business_Description')}
                                onBlur={() => setEditing({ rowIndex: null, field: null, scope: null })}
                            /> :
                            <span 
                                className="editable-field" 
                                onDoubleClick={() => setEditing({ rowIndex: index, field: 'Business_Description', scope: 'column' })}>
                                {column.Business_Description || "Click to edit"}
                            </span>
                        }
                      </td>

                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="justify-content-end">
              
              <Button variant="primary" className="me-2" onClick={fetchRecommendations}>Fetch Recommendations</Button>

              {/* <Button variant="secondary" className="me-2">Edit</Button> */}

              <Button variant="success" className="me-2" onClick={saveMetadata}>Save</Button>

              <Button variant="warning">Export</Button>
            </div>

          </div>
          
        )
        : <>
          <div className="placeholder">
            <p className="text-dark">Select a table from the Data Catalog to display Metadata.</p>
          </div>
          </>
        )
      }

      </Col>
    </Row>
    
    </Container>
    </>
  )
}
