import { Accordion, Container, Row, Col, Table, Card, Alert, Pagination, Modal, Button  } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Showcase() {

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // or however many you want per page

  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Fetch the data from the API
    axios.get('https://jsonplaceholder.typicode.com/posts')
      .then(response => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const handleShowClick = (post) => {
    setSelectedPost(post);
    axios.get(`https://jsonplaceholder.typicode.com/comments?postId=${post.id}`)
      .then(response => {
        setComments(response.data);
        setShowModal(true);
      })
      .catch(err => {
        setError(err.message);
      });
  }


  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <>
    <Container className="content">
    <h1>Solution Showcase</h1>
    <Row>
      <Col>
      
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>💡 Ready to Get Started?</Accordion.Header>
          <Accordion.Body>
            Reach out to <a href="#">KPMG Lighthouse</a> for more details.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      </Col>
    </Row>
    <Row>
          {loading && (
            <Col>
              <Alert variant="info">Loading...</Alert>
            </Col>
          )}

          {error && (
            <Col>
              <Alert variant="danger">Error: {error}</Alert>
            </Col>
          )}

          {!loading && !error && currentPosts.map(post => (
            <Col md={4} key={post.id}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Text>{post.body}</Card.Text>
                  <div className="mt-auto d-flex justify-content-end">
                    <Button variant="primary" onClick={() => handleShowClick(post)}>
                      Show
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Pagination className="mt-3">
          {Array(Math.ceil(posts.length / postsPerPage)).fill().map((_, idx) => (
            <Pagination.Item key={idx} active={idx + 1 === currentPage} onClick={() => paginate(idx + 1)}>
              {idx + 1}
            </Pagination.Item>
          ))}
        </Pagination>


        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{selectedPost?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedPost?.body}
            <hr />
            <h5>Comments:</h5>
            <div className="post-comments-container">
              {comments.map(comment => (
                <div key={comment.id}>
                  <strong>{comment.name}</strong>
                  <p>{comment.body}</p>
                  <hr />
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

    </Container>
    </>
  )
}
